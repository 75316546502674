(function(app) {
    'use strict';

    //pa-disease
    app.directive('paDisease', function(_view, _apiService, _i18n) {
        return {
            templateUrl: '/js/components/pa/disease.html',
            restrict: 'A',
            scope: {
                disease: '=',
                index: '=',
                plantsNumber: '=',
                all: '=',
                crop: '='
            },
            link:  function (scope) {
                scope.disease.number = scope.plantsNumber;
                scope.diseases = [];

                scope.remove = function (i) {
                    scope.all.splice(i, 1);
                };

                scope.diseaseChanged = function () {
                    _apiService.diseaseScales(_view.getCurrentAccount(), scope.disease.disease.id).then(function(res) {
                        scope.scales = _.map(res.data, function (e) {
                            return {id: e.id, formName: _i18n.getStringFromBundleEn(e.name)};
                        });
                        scope.disease.scale = scope.scales[0];
                    });
                };

                _apiService.getDiseases(_view.getCurrentAccount()).then(function(res) {

                    _.each(res.data, function(i) {
                        if (scope.crop && _.contains(_.map(i.crops, function (e) { return e.id;}), scope.crop.variety.crop.id)) {
                            i.formName = _i18n.getStringFromBundleLat(i.name);
                            scope.diseases.push(i);
                        }
                    });

                    scope.diseases = _.sortBy(scope.diseases, i => i.formName);

                    if (_.size(scope.diseases) > 0) {
                        scope.disease.disease = scope.diseases[0];
                        scope.diseaseChanged();
                    }
                });
            }
        };
    });
})(angular.module('app'));
